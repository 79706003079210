#root {
  background-color: black;
}

.container-common {
  margin: 0 auto;
  width: 1200px;
}

.nav-bar {
  backdrop-filter: blur(10px);
  background-color: rgba(13, 13, 13, 0.85);
  width: 100%;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  opacity: 1;
}

@media only screen and (max-width: 1280px) {
  .container-common {
    width: 90%;
  }
}

@media only screen and (max-width: 640px) {
  .vertical-timeline-element-content {
    width: 100%;
  }
}

.highlighted-gradient {
  background: linear-gradient(
    90deg,
    rgba(34, 193, 195, 1) 0%,
    rgba(253, 187, 45, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-items-mobile .nav-items {
  transition: transform 0.3s ease-in-out;
}

.rotating-img {
  animation: rotate 50s linear infinite;
}

@media only screen and (max-width: 640px) {
  .aboutus {
    background-image: none !important;
  }
}

.elementor-background-overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

/* circuit animation */

.connection-lines {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.connection-line {
  stroke: #ffffff;
  stroke-width: 2;
  fill: transparent;
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  animation: drawLine 2s ease forwards;
}

@keyframes drawLine {
  to {
    stroke-dashoffset: 0;
  }
}

.cards-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.tab {
  border: 2px solid rgb(39, 38, 38);
  opacity: 0.7;
  color: #fff;
  background-color: rgba(26, 70, 81, 0.4);
  border-radius: 2px;
  flex: 1;
  padding-top: 12px;
  padding-bottom: 12px;
}

.tab.active {
  border-bottom-width: 3px;
  border-bottom-color: rgb(255, 166, 0);
  opacity: 1;
  color: rgb(255, 166, 0);
  background-color: rgba(26, 70, 81, 0.4);
  flex: 1;
}

.timeline-line {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background: linear-gradient(to bottom, #9c4c9f, #006d3d);
}

.timeline-card {
  position: relative;
  z-index: 100;
  left: -10px;
  background: rgba(255, 255, 255, 0.1); /* Transparent white background */
  padding: 20px;
  border-radius: 8px;
  transition: transform 0.5s ease-in-out;
  backdrop-filter: blur(10px); /* Apply the glass effect */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Optional: adds a subtle border */
}

.card-effect {
  position: relative;
  z-index: 100;
  background: rgba(255, 255, 255, 0.1); /* Transparent white background */
  border-radius: 8px;
  transition: transform 0.5s ease-in-out;
  backdrop-filter: blur(10px); /* Apply the glass effect */
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.timeline-card.active {
  transform: scale(1.05);
  opacity: 1;
}

/* animation loading */

#container {
  margin: auto auto;
}

.loading-title {
  display: block;
  text-align: center;
  font-size: 20;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  padding-bottom: 15px;
  padding-left: 40px;
  color: #888;
}

.loading-circle {
  display: block;
  border-left: 5px solid;
  border-top-left-radius: 100%;
  border-top: 5px solid;
  margin: 5px;
  animation-name: Loader_611;
  animation-duration: 1500ms;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

.sp1 {
  border-left-color: #f44336;
  border-top-color: #f44336;
  width: 40px;
  height: 40px;
}

.sp2 {
  border-left-color: #ffc107;
  border-top-color: #ffc107;
  width: 30px;
  height: 30px;
}

.sp3 {
  width: 20px;
  height: 20px;
  border-left-color: #8bc34a;
  border-top-color: #8bc34a;
}

@keyframes Loader_611 {
  0% {
    transform: rotate(0deg);
    transform-origin: right bottom;
  }

  25% {
    transform: rotate(90deg);
    transform-origin: right bottom;
  }

  50% {
    transform: rotate(180deg);
    transform-origin: right bottom;
  }

  75% {
    transform: rotate(270deg);
    transform-origin: right bottom;
  }

  100% {
    transform: rotate(360deg);
    transform-origin: right bottom;
  }
}

/* about us css */

.bg-fixed-opacity {
  background-image: url("../public/assets/cloud-bg.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1.4;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* salesforce us css */

.bg-salesforce-opacity {
  background-image: url("../public/assets/salesforce-bg.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* sales us css */

.bg-sales-opacity {
  background-image: url("../public/assets/salesforce-bg.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* pardot us css */

.bg-pardot-opacity {
  background-image: url("../public/assets/pardot-bg.avif");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.9;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* cloud us css */

.bg-cloud-opacity {
  background-image: url("../public/assets/aws-bg.png");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  filter: blur(3px);
  height: 100%;
}

/* oracle us css */

.bg-oracle-opacity {
  background-image: url("../public/assets/oracle-bg.webp");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(3px);
  width: 100%;
  height: 100%;
}

/* ai us css */

.bg-ai-opacity {
  background-image: url("../public/assets/ai-bg.jpeg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.8;
  position: absolute;
  top: 0;
  filter: blur(3px);
  left: 0;
  width: 100%;
  height: 100%;
}

/* data us css */

.bg-data-opacity {
  background-image: url("../public/assets/data-bg.avif");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(3px);
  width: 100%;
  height: 100%;
}

/* crm us css */

.bg-crm-opacity {
  background-image: url("../public/assets/crm-bg.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(3px);
  width: 100%;
  height: 100%;
}

/* devops us css */

.bg-devops-opacity {
  background-image: url("../public/assets/devops-bg.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(3px);
  width: 100%;
  height: 100%;
}

/* strategy us css */

.bg-strategy-opacity {
  background-image: url("../public/assets/strategy-bg.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(3px);
  width: 100%;
  height: 100%;
}

/* delivery us css */

.bg-delivery-opacity {
  background-image: url("../public/assets/delivery-bg.jpeg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(3px);
  width: 100%;
  height: 100%;
}

/* outsourcing us css */

.bg-outsourcing-opacity {
  background-image: url("../public/assets/outsourcing-bg.webp");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(3px);
  width: 100%;
  height: 100%;
}

/* outsourcing us css */

.bg-services-opacity {
  background-image: url("../public/assets/services-bg.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(3px);
  width: 100%;
  height: 100%;
}

/* career us css */

.bg-career-opacity {
  background-image: url("../public/assets/career-bg.avif");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(3px);
  width: 100%;
  height: 100%;
}

/* contact us css */

.bg-contact-opacity {
  background-image: url("../public/assets/contactus-bg.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(3px);
  width: 100%;
  height: 100%;
}


@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.scroller {
  overflow: hidden;
}

.animate-scroll {
  animation: scroll var(--animation-duration) linear infinite;
}



